export class BrainServiceData {
  constructor(axiosClient, brainFetchClient) {
    this.axiosClient = axiosClient;
    this.brainFetchClient = brainFetchClient;
  }

  // FETCH METHODS

  async *fetchSummary(accessToken, body, summaryType = 'long') {
    const URL = 'ai/summarize';

    for await (const chunk of this.brainFetchClient.fetchStream(URL, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${accessToken}`,
      },
      body: JSON.stringify({
        text: body,
        summaryType,
      }),
    })) {
      yield chunk;
    }
  }

  async *fetchActionItems(accessToken, body, abortController) {
    const URL = 'ai/action-items';

    for await (const chunk of this.brainFetchClient.fetchStream(URL, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${accessToken}`,
      },
      signal: abortController ? abortController.signal : null,
      body: JSON.stringify({
        text: body,
      }),
    })) {
      yield chunk;
    }
  }
  async fetchKnowledgeManagerRecommendations(accessToken, body) {
    const response = await this.axiosClient.post(
      'ai/knowledge-manager',
      {
        text: body,
      },
      {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${accessToken}`,
        },
      },
    );

    return response;
  }

  async *fetchTranslation(
    accessToken,
    text,
    language = 'en-us',
    abortController,
  ) {
    const URL = 'ai/translate';
    try {
      for await (const chunk of this.brainFetchClient.fetchStream(URL, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${accessToken}`,
        },
        signal: abortController ? abortController.signal : null,
        body: JSON.stringify({
          doc: text,
          lang_to: language,
        }),
      })) {
        yield chunk;
      }
    } catch (error) {
      console.error(error);
    }
  }

  async *fetchCorrection(accessToken, text, correctionType, abortController) {
    const URL = 'ai/correct';
    try {
      for await (const chunk of this.brainFetchClient.fetchStream(URL, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${accessToken}`,
        },
        signal: abortController ? abortController.signal : null,
        body: JSON.stringify({
          doc: text,
          correctionType,
        }),
      })) {
        yield chunk;
      }
    } catch (error) {
      console.error(error);
    }
  }

  async *fetchImprove(accessToken, text, abortController) {
    const URL = 'improve';

    try {
      for await (const chunk of this.brainFetchClient.fetchStream(URL, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${accessToken}`,
        },
        signal: abortController ? abortController.signal : null,
        body: JSON.stringify({
          doc: text,
          improvements: [],
        }),
      })) {
        yield chunk;
      }
    } catch (error) {
      console.error(error);
    }
  }
  async *fetchOpenPrompt(accessToken, text, abortController, query) {
    const URL = 'ai/open-prompt';

    try {
      for await (const chunk of this.brainFetchClient.fetchStream(URL, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${accessToken}`,
        },
        signal: abortController ? abortController.signal : null,
        body: JSON.stringify({
          doc: text,
          user_prompt: query,
        }),
      })) {
        yield chunk;
      }
    } catch (error) {
      console.error(error);
    }
  }

  async *askStream(
    accessToken,
    queryId,
    queryBody,
    dev = false,
    askInterface = 'PRIVATE',
  ) {
    const URL = dev ? 'ask/dev' : 'ask/stream';
    try {
      for await (const chunk of this.brainFetchClient.fetchStream(URL, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${accessToken}`,
          'X-Correlation-Id': queryId,
          'X-Ask-interface': askInterface,
        },
        body: JSON.stringify(queryBody),
      })) {
        yield chunk;
      }
    } catch (error) {
      console.error(error);
      yield { type: 'error', content: error };
    }
  }

  // AXIOS METHODS

  getParameters() {
    return this.axiosClient.get('ask/parameters');
  }

  validateParameters(options) {
    return this.axiosClient.post('ask/parameters', options, {
      headers: { 'Content-Type': 'application/json' },
    });
  }

  async sendAskFeedback(accessToken, queryId, queryBody, feedback) {
    try {
      return await this.axiosClient.put(
        'ask/feedback',
        {
          query: queryBody,
          ...feedback,
        },
        {
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${accessToken}`,
            'X-Correlation-Id': queryId,
          },
        },
      );
    } catch (error) {
      console.error(error);
      return error;
    }
  }

  async sendAskBetaFeedback(accessToken, queryId, queryBody, feedback) {
    try {
      return await this.axiosClient.put(
        'ask/feedback/beta',
        {
          query: queryBody,
          ...feedback,
        },
        {
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${accessToken}`,
            'X-Correlation-Id': queryId,
          },
        },
      );
    } catch (error) {
      console.error(error);
      return error;
    }
  }

  async analyzeCustomizationParameters(accessToken, queryId, textSpec) {
    try {
      return await this.axiosClient.post(
        'ask/customization/analyze',
        {
          textSpec,
        },
        {
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${accessToken}`,
            'X-Correlation-Id': queryId,
          },
        },
      );
    } catch (error) {
      console.error(error);
      return error;
    }
  }

  async getCustomization(accessToken, queryId) {
    try {
      return await this.axiosClient.get('ask/customization/', {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${accessToken}`,
          'X-Correlation-Id': queryId,
        },
      });
    } catch (error) {
      console.error(error);
      return error;
    }
  }

  async setCustomization(accessToken, queryId, customization) {
    try {
      return await this.axiosClient.post('ask/customization/', customization, {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${accessToken}`,
          'X-Correlation-Id': queryId,
        },
      });
    } catch (error) {
      console.error(error);
      return error;
    }
  }

  async delCustomization(accessToken, queryId) {
    try {
      return await this.axiosClient.delete('ask/customization/', {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${accessToken}`,
          'X-Correlation-Id': queryId,
        },
      });
    } catch (error) {
      console.error(error);
      return error;
    }
  }

  async postArticleToGuide(
    objective,
    label,
    body,
    language,
    knowledge,
    knowledgeId,
    caseParameters,
  ) {
    return this.axiosClient.post('/ai/decision-tree', {
      objective,
      label,
      body,
      language,
      knowledge,
      knowledgeId,
      caseParameters,
    });
  }

  async getArticleToGuideStatus(processId) {
    return this.axiosClient.get(`ai/decision-tree/${processId}`);
  }

  async cancelArticleToGuide(processId) {
    return this.axiosClient.delete(`ai/decision-tree/${processId}`);
  }

  async getArticleToGuideSuggestion(label, body, language) {
    return this.axiosClient.post('ai/decision-tree/suggest', {
      label,
      body,
      language,
    });
  }

  async postTryOutQuestions({ lang, n }) {
    return this.axiosClient.post('/ask/suggest', {
      language: lang,
      n,
      method: 'most-recent',
    });
  }

  // concepts

  async rejectConcept({ documentId, conceptId }) {
    return this.axiosClient.put(
      `/concept/user-reject/${documentId}/${conceptId}`,
    );
  }

  async acceptConcept({ documentId, conceptId }) {
    return this.axiosClient.put(`/concept/user-add/${documentId}/${conceptId}`);
  }

  async searchConcepts(payload) {
    return this.axiosClient.post(`/concept/search`, payload);
  }
  async createConcept(concept) {
    return this.axiosClient.put(`/concept-create`, concept);
  }
  async deleteConcept(conceptId) {
    return this.axiosClient.delete(`/concept/${conceptId}`);
  }
  async sendSuggestedDocs({ sessionId, answers }) {
    return this.axiosClient.patch(`/concept-create/suggest-docs/${sessionId}`, {
      answers,
    });
  }

  // Translations

  async triggerContentTranslation({
    documentId,
    sourceLanguage,
    targetLanguage,
  }) {
    return this.axiosClient.post(`/translate/document`, {
      documentId,
      sourceLanguage,
      targetLanguage,
    });
  }

  async getTranslationStatus(lockId) {
    return this.axiosClient.get(`/translate/document/${lockId}`);
  }

  // File Import

  async getFileImportStatus(lockId) {
    return this.axiosClient.get(`/import/document/${lockId}`);
  }

  // Files Merge

  async getFilesMergeStatus(lockId) {
    return this.axiosClient.get(`/merge/document/${lockId}`);
  }

  // Content cover generation
  async triggerImageCoverGeneration(text, colors = []) {
    return this.axiosClient.put('/banner-image', {
      text,
      colors,
    });
  }

  async getImageCoverGenerationStatus(lockId) {
    return this.axiosClient.get(`/banner-image/${lockId}`);
  }
}
